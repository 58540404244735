import React, { useEffect, useState } from "react";
import { sistema } from "../../model/Sistema";
import Escritorio from "../Escritorio";


export default ({mesa, color, seleccionado, onChange, ...props}) => {

  const [puestos, setPuestos] = useState([]);
  const [puesto, setPuesto] = useState(seleccionado);

  useEffect(() => {
    if (mesa && mesa.puestos) {
      setPuestos(mesa.puestos);
    }
  }, [mesa]);

  const puestoSeleccionado = (item) => {
    if (puesto) {
      puesto.status = "FREE";
      puesto.usuarios = [];
    }
    if (!item.usuarios) {
      item.usuarios = [];
    }
    item.usuarios.push(sistema.usuario);
    item.status = "TAKEN";
    setPuesto(item);
    if (onChange) {
      onChange(mesa, item);
    }
  }

  return (
    <div style={{backgroundColor: color}} className={"mesa mesa--" + (mesa ? mesa.cantidadDePuestos : 0)}>
        <ol className="mesa__puestos">
          {puestos && puestos.length >0 &&
          puestos.map((item, index) => (
            <li className="mesa__puesto" key={index}>
              <Escritorio seleccionado={puesto} onClick={(event) => {puestoSeleccionado(item)}} habilitado={item.habilitado} posicion={(index < (puestos.length / 2)) ? 'TOP' : 'BOTTOM'} numero={item.id} status={item.status} usuarios={item.usuarios} />
            </li>
          ))}
        </ol>

    </div>
);
}