import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import Draggable from "react-draggable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from "../../model/Sistema";
import APIManager from '../../managers/APIManager';

export default ({ usuario, ...props }) => {
  const [codigo, setCodigo] = useState(null);

  const history = useHistory();

  const continuarReservando = () => {
    history.push('/reservas');
  }

  const titulo = () => {
    switch (sistema.actual.accion)
    {
      case 'compraDeCreditos':
        return '¡Tu solicitud de créditos fue enviada con éxito!';
      default:
        return '¡Tu reserva fué ' + (sistema.actual.evento ? 'cancelada' : 'realizada') + ' con éxito!';
    }

  }

  const obtenerCodigoQR = () => {
    APIManager.getCode('access', (data) => {
      if (!!data.codigo) {
        setCodigo(data.codigo);
      } else {
        setCodigo(null);
      }
    });
  }

  useEffect(() => {
    obtenerCodigoQR();
   }, []);

  return (
    <div className="qr-page">
      <TituloConBack titulo={"QR de Acceso"} />
      {!!codigo
        ? <>
          <p className="instrucciones">Mostrá el QR para acceder a la sede.</p>
          {/* <div className="wrapper--qr-code">
            <QRCode value="1234567890" renderAs="svg" />
          </div> */}
          <Draggable>
            <div className="wrapper--qr-code">
              <QRCode className="codigo-QR" value={codigo} renderAs="svg" />
            </div>
          </Draggable>
          <p className="instrucciones--extra">Podés arrastrar el QR dentro de la pantalla.</p>
        </>
        : <p className="instrucciones">Para obtener un QR de acceso a la sede, primero debes realizar alguna reserva para el día de hoy.</p>
      }
    </div>
  );
}