import { sendRequest, setAuthToken } from './serverAPI';

const redirectToLoginApp = () => {
  var loginURL = process.env.REACT_APP_LOGIN_URL;

  var callback = {
    'url': window.location.origin,
    'type': 'mimqco'
  };
  var form = document.createElement('form');
  var callbackInput = document.createElement('input');

  form.method = 'POST';
  form.action = loginURL;

  callbackInput.value = JSON.stringify(callback);
  callbackInput.name = 'callback';
  callbackInput.type = 'hidden';
  form.appendChild(callbackInput);

  document.body.appendChild(form);

  form.submit();
}

const getLoginToken = () => {
  let querySearch = new URLSearchParams(window.location.search);
  let loginToken = null;
  if (querySearch.has('loginToken'))
  {
    loginToken = querySearch.get('loginToken')
  }
  return loginToken;
}

export const login = (setUsuario) => {
  // Si viene el loginToken como parametro en el request, estamos en el proceso de login
  const loginToken = getLoginToken();

  if (loginToken) {
    window.history.replaceState({}, document.title, "/");
    // Tenemos el loginToken para hacer login con el backend
    let postData = {
      accion: 'login',
      type: 'loginToken',
      token: loginToken,
      originType: 'mimqco',
      rol: 'ROL_MIEMBRO'
    }

    sendRequest('post', 'usuario', 'login', postData, (response) => {
      if (response.success) {
        if (response.data.authToken)
        {
          setAuthToken(response.data.authToken);
        }
        setUsuario(response.data.usuario);
        localStorage.actual = JSON.stringify({});
      }
    })
  }
  else
  {
    // No vino el loginToken, Intentamos obtener info del server
    sendRequest('post', null, 'me', {}, (response) => {
      if (response.success) {
        // Si devuelve OK, estamos logueados y tenemos el authToken
        if (response.data.authToken) {
          setAuthToken(response.data.authToken);
        }
        setUsuario(response.data);
      } else {
        // si devuelve usuario invalido, tenemos que hacer redirect a la loginApp para obtener el loginToken
        redirectToLoginApp();
      }

    });
  }
};

export const logout = (setUsuario) => {
  // Intentamos obtener info del server
  localStorage.actual = JSON.stringify({});
  sendRequest('post', 'usuario', 'logout', {}, (response) => {
    if (response.success)
    {
      // Si devuelve OK, estamos logueados y tenemos el authToken
      setUsuario(null);
    }
  });
};