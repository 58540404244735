import React from "react";

const TarjetaDeContrato = ({ contrato, usuario, adminAccess, onVerContrato, onFirmarContrato }) =>
{
  return (!!contrato &&
    <div className="contrato-card membresia-wrapper">
      <h1 className="membresia__titulo">{contrato.nombre}</h1>
      {!contrato.firmaURL && <span className="attention" >Pendiente de firma !!</span>}
      <div className="contrato__info">
        {!!adminAccess &&
          <>
            <button className="contrato__button button--icon-only" onClick={onVerContrato}>
              <i className="icon--file-pdf"></i>
              <span>Ver Contrato</span>
            </button>
            {!contrato.firmaURL &&
            <button className="contrato__button attention button--icon-only" onClick={onFirmarContrato}>
              <i className="icon--file-signature"></i>
              <span>Firmar Contrato</span>
            </button>}
          </>
        }
      </div>
      <h1 className="membresia__titulo">{contrato.creditos + ' créditos renovables'}</h1>
      <h1 className="membresia__titulo">{'Saldo: ' + usuario.creditos.balance}</h1>
      {/*
    <h1 className="membresia__titulo">{usuario.contrato ? 'Membresías' : 'Sin Membresía'}</h1>
    <hr />
    {usuario.contrato &&
      Object.values(usuario.contrato.puestos).map(item => item.puestos ? <Membresia key={item.id} {...item} conPuestos={true} /> : null)
    }
*/}
    </div>)
}

export default TarjetaDeContrato;