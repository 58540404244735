import React from 'react';
import Evento from '../EventoDeHistorial';

export default ({eventos, ...props}) => {

  return (
    <section className="my-week">
      <ol className="event-list">
        {eventos && (eventos.length > 0) && eventos.map((item, index) => (
          <li key={index} className={"event-item" + (item.tipo === 'Evento' ? ' event-type--purple' : '')}>
            <Evento evento={item} />
          </li>
        ))}
        <li><span className="control--ver-mas">{'mostrando ' + eventos.length + ' registros'}
          {/* <img className="control--ver-mas__icon" src="img/chevron--orange.svg"></img> */}
        </span></li>
      </ol>
    </section>
  );
}