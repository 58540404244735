import React, { useEffect } from "react";


export default ({habilitado, numero, posicion, status, usuarios, onClick, seleccionado, ...props}) => {

  const imagenDeUsuarios = (usuarios) => {
    //TODO: Re-armar para mostrar todos los usuarios
    return usuarios && (usuarios.length > 0) ? usuarios[0].profile_photo : null;
  };

  const nombreDeUsuarios = (usuarios) => {
    return usuarios && (usuarios.length === 1) ? usuarios[0].nombres : '';
  }

  return (
    <div className={"escritorio escritorio" + (!habilitado ? '--inhabilitado' : ((status === 'FREE') ? '--habilitado' : ((seleccionado && (seleccionado.id === numero)) ? '--reservado' : '--ocupado')))}>
      <button className="escritorio__control" disabled={!habilitado || (status !== 'FREE')} onClick={onClick}>
        <span className="escritorio__posicion">{numero}</span>
        <div className="escritorio__ocupantes">
          {imagenDeUsuarios(usuarios)
            ? <img className="escritorio__ocupantes-avatar-image" src={imagenDeUsuarios(usuarios)} alt="" />
            : <div className="escritorio__ocupantes-avatar-image"><span className="escritorio__ocupantes-avatar-image__text">{usuarios && (usuarios.length > 0) ? usuarios[0].nombres[0]+usuarios[0].apellidos[0] : null}</span></div>
          }
          <div className="escritorio__ocupantes-nombre">
            <span className={"nombres--" + (usuarios ? usuarios.length : 0)}>{nombreDeUsuarios(usuarios)}</span>
          </div>
        </div>
      </button>
      <div className={"silla silla--" + posicion}><img className="silla__image" src="/img/silla.png" alt="" /></div>
    </div>
  );
}