import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FixedCTA from "../../components/FixedCTA";
import PaqueteDeCreditos from "../../components/PaqueteDeCreditos";
import TituloConBack from "../../components/TituloConBack";
import { sendRequest } from '../../lib/serverAPI';
import { sistema } from "../../model/Sistema";

export default () => {

  const [paquetes, setPaquetes] = useState([]);
  const [paquete, setPaquete] = useState(null);

  const history = useHistory();

  useEffect(() => {
    sendRequest('post', 'query', 'getList', {lista: 'paquetesDeCreditos'}, (response) => {
      if (response.success) {
        setPaquetes(response.data.listado);
      }

    });
  }, []);

  const solicitarCreditos = () => {
    sendRequest('post', 'creditos', 'compraDeCreditos', paquete, (response) => {
      if (response.success) {
        sistema.actual.accion = 'compraDeCreditos';
        sistema.actual.paquete = paquete;
        sistema.saveActual();
        history.push('/exito');
      }
    });
  }

  return (
    <div className="solicitud-de-creditos-page">
      <TituloConBack titulo="Compra de créditos" />
      <ul className="paquetes-list">
        {paquetes && (paquetes.length > 0) && paquetes.map((item, index) =>
          <li className="paquetes-list-item" key={index} >
            <PaqueteDeCreditos {...item} checked={paquete === item} onClick={() => {setPaquete(item)}} />
          </li>
        )}
      </ul>
      <FixedCTA label="Solicitar créditos" onClick={solicitarCreditos} />
    </div>
  )
}