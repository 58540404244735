import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FixedCTA from "../../components/FixedCTA";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from "../../model/Sistema";
import 'moment/locale/es';
import Moment from "react-moment";
import { sendRequest } from '../../lib/serverAPI';

export default ({usuario, ...props}) => {
  const history = useHistory();

  const [djFirmada, setDJFirmada] = useState(true);
  const [habilitado, setHabilitado] = useState(true);

  if ((!sistema.actual || !sistema.actual.accion) && localStorage.actual) {
    sistema.actual = JSON.parse(localStorage.actual);
  }
  if (!sistema.actual || !sistema.actual.accion)
  {
    history.push('/home');
  }

  const pronombre = () => {
    switch (sistema.actual.accion) {
      case 'reservarEscritorio':
        return 'el';
      case 'reservarMiOficina':
        return 'tu puesto';
      case 'reservarSala':
        return 'la sala ';
        default:
        return '';
    }

  }

  const descripcion = () => {
    switch (sistema.actual.accion) {
      case 'reservarEscritorio':
        return 'puesto ' + sistema.actual.puesto.id;
      case 'reservarSala':
        return sistema.actual.sala.nombre;
      default:
        return '';
    }
  }

  const ubicacion = () => {
    switch (sistema.actual.accion) {
      case 'reservarEscritorio':
        return sistema.actual.mesa.nombre;
      case 'reservarMiOficina':
        return sistema.actual.oficina.nombre;
      default:
        return '';
    }
  }

  const confirmar = (event) => {
    event.target.setAttribute("disabled", true);
    setHabilitado(false);
    if (habilitado) {
      sendRequest('post', 'reservas',sistema.actual.accion, sistema.actual, (response) => {
        if (response.success) {
          sistema.usuario.creditos.balance -= response.data.costo;
          history.push('/exito');
        } else {
          // Hay errores
        }
      })
    }

  }

  const detalle = () => {
    switch (sistema.actual.accion) {
      case 'reservarEscritorio':
        return <>
          el día <span><Moment format="dddd D [de] MMMM" locale="es">{sistema.actual.fecha}</Moment></span><br/>
          turno <span>{sistema.actual.turno.titulo}</span>
        </>;
      case 'reservarMiOficina':
        let prefijo = '';
        if (sistema.actual.reservas.length > 1) {
          prefijo = 'los días ';
        } else {
          prefijo = 'el día ';
        }
        return <>{prefijo} {sistema.actual.reservas.map((item, index) => item ? <span key={index}><Moment format="dddd D [de] MMMM" locale="es">{item.fecha}</Moment>{(sistema.actual.reservas.length > 1) && (index < sistema.actual.reservas.length-2) ? ', ' : (index < sistema.actual.reservas.length-1) ? ' y ' : ''}</span> : '')}</>;
      case 'reservarSala':
        return <>
          el día <span><Moment format="dddd D [de] MMMM" locale="es">{sistema.actual.fecha}</Moment></span><br/>
          a las <span>{sistema.actual.hora.nombre}</span> durante <span>{sistema.actual.duracion.nombre}</span>
        </>;

      default:
        return null;
    }
  }

  const tituloPara = (accion) => {
    switch (accion) {
      case 'reservarMiOficina':
        return 'Reserva de Oficina';
      case 'reservarSala':
        return 'Reserva de Sala';
      default:
        return 'Reserva de Posición';
    }
  }

  const subtituloPara = (accion) => {
    switch (accion) {
      default:
        return 'Estás reservando';
    }
  }

  return ((!sistema || !sistema.actual || !sistema.actual.accion) ? null :
    <div className="confirmar-page">
      <TituloConBack titulo={tituloPara(sistema.actual.accion)} />
      <div className="confirmar__subtitulo">
        <span>Revisá que esté todo bien </span><span role="img" aria-label="ok">👍</span>
        <hr />
      </div>
      <p className="confirmar__detalle">
        {subtituloPara(sistema.actual.accion)}<br/>
        {pronombre()} <span>{descripcion()}</span><br/>
        {ubicacion() && <> en <span>{ubicacion()}</span><br/></>}
        {sistema.actual.espacio && <>de <span>{sistema.actual.espacio.nombre}</span><br/></>}
        {sistema.actual.sector ? <>sector <span>{sistema.actual.sector.nombre}</span><br/></> : null}
        {detalle()}
{/*         <button className={"confirmar__ddjj" + (djFirmada ? ' firmada' : '')} onClick={() => { setDJFirmada(!djFirmada) }} >
          <i className="confirmar__ddjj-icon icon--check"></i>
          <label className="confirmar__ddjj-text">Confirmo no asistir a la sede en este dia y horario el caso de tener sintomas de COVID-19</label>
        </button>
 */}
      </p>
      <FixedCTA disabled={(!usuario.habilitado || !djFirmada) && habilitado} className={(sistema.actual.costo === 0) ? 'sin-leyenda' : ''}  titulo={(sistema.actual.costo !== 0) ? (sistema.actual.costo + " Créditos") : '' } leyendaPre="Te quedarán" leyendaBold={usuario.creditos.balance - sistema.actual.costo} leyendaPost="créditos" label="Confirmar" onClick={confirmar}/>
    </div>
  );
}