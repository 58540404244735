import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sistema } from "../../model/Sistema";

export default ({usuario, ...props}) => {
  const history = useHistory();

  if (!sistema.actual || !sistema.actual.accion)
  {
    sistema.loadActual();
    if (!sistema.actual.accion) {
      history.push('/home');
    }
  }


  const continuarReservando = () => {
    history.push('/reservas');
  }

  const titulo = () => {
    switch (sistema.actual.accion)
    {
      case 'compraDeCreditos':
        return '¡Tu solicitud de créditos fue enviada con éxito!';
      default:
        return '¡Tu reserva fué ' + (sistema.actual.evento ? 'cancelada' : 'realizada') + ' con éxito!';
    }

  }

  return (
    <div className="exito-page">
      <i className="exito__icon icon--check"></i>
      <h1 className="exito__titulo">{titulo()}</h1>
      {!!sistema.actual.costo && (sistema.actual.costo !== 0) && <>
        {sistema.actual.evento ? <span className="exito__leyenda">Te devolvimos <span className="bold">{sistema.actual.costo}</span> créditos</span> : null}
        <span className="exito__leyenda">Te quedan <span className="bold">{usuario.creditos.balance}</span> créditos</span>
      </>}
      {!!sistema.actual.paquete && <>
          <span className="exito__leyenda">Contactate con nuestro staff para realizar y confirmar el pago.</span>
          <span className="exito__leyenda">Al confirmar el pago, se te acreditará la compra.</span>
          <button onClick={()=> {history.push('/home')}} className="exito__control">Volver al inicio</button>
        </>
      }
      {!sistema.actual.paquete &&
        <button onClick={continuarReservando} className="exito__control">Continuar reservando</button>
      }
    </div>
  );
}