import React, { useEffect } from 'react';

import Cover from './components/Cover';
import AccesosRapidos from '../../components/AccesosRapidos';
import MiSemana from '../../components/MiSemana';
import MisAcciones from '../../components/MisAcciones';
import ExperienciasDestacadas from '../../components/ExperienciasDestacadas';
import Experiencias from '../../components/Experiencias';
import { sistema } from '../../model/Sistema';
import { useHistory } from 'react-router-dom';

export default function Home({ ...props }) {

  const history = useHistory();

  sistema.actual = {};
  localStorage.actual = JSON.stringify(sistema.actual);

  useEffect(() => {
    // Redireccion a onboarding si el usuario es nuevo (para que termine su perfil)
    if (props.usuario && props.usuario.status && (props.usuario.status === 'new')) {
      history.push("/onboarding");
    }

  }, []);

  return (
    <div className="home-page">
      <Cover slider={false} images={["/img/cover-home-"+ (props.isPortrait ? 'mobile' : 'desktop') + '.png']} />
      { props.isPortrait ?
        <>
          {(props.usuario.status !== 'approved') && !props.usuario.contrato &&
            <section className="my-events">
              <MisAcciones titulo={"¿Que querés reservar?"} filtros={{ tipos: ['reservar'] }} {...props} />
            </section>
          }
          <AccesosRapidos {...props} />
          <MiSemana titulo={"Mi Semana"} {...props} />
          <ExperienciasDestacadas />
        </>
        :
        <div className="wrapper--scrollable-content__layout">
          <div className="wrapper--scrollable-content__column">
            <AccesosRapidos {...props} />
            <ExperienciasDestacadas />
          </div>
          <div className="wrapper--scrollable-content__column">
            <section className="my-events">
              {/* {(props.usuario.status !== 'approved') && props.usuario.contrato && */}
                <MisAcciones titulo={"¿Que querés reservar?"} filtros={{tipos: ['reservar']}} {...props} />
              {/* } */}
              <MiSemana titulo={"Mi Semana"} {...props} />
            </section>
          </div>
        </div>
      }
      <Experiencias {...props} />
    </div>
  );
}
