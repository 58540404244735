import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Selector from "../../components/Selector";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from '../../model/Sistema';
import { getUIData } from '../../lib/serverAPI';
import Mesa from "../../components/Mesa";
import FixedCTA from "../../components/FixedCTA";
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { displayName } from "qrcode.react";


export default ({...props}) => {
  const [mesa, setMesa] = useState(sistema && sistema.actual && sistema.actual.mesas && (sistema.actual.mesas.length > 0) && !sistema.actual.mesa ? sistema.actual.mesas[0] : (sistema.actual && sistema.actual.mesa ? sistema.actual.mesa : null));
  const [mesas, setMesas] = useState(sistema && sistema.actual && sistema.actual.mesas ? sistema.actual.mesas : []);
  const [mesasFolded, setMesasFolded] = useState(true);
  const [puesto, setPuesto] = useState(sistema && sistema.actual && sistema.actual.puesto ? sistema.actual.puesto : null);
  const [sector, setSector] = useState(sistema && sistema.actual && sistema.actual.sector ? sistema.actual.sector : null);
  const [sectorVisible, setSectorVisible] = useState(true);
  const [hayTour, setHayTour] = useState(false);
  const [hayFoto, setHayFoto] = useState(false);
  const [hayMapa, setHayMapa] = useState(false);
  const [hayColor, setHayColor] = useState(false);
  const [tourVisible, setTourVisible] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(false);

  const verImagenes = sistema.verFotosDeSedes;

  const history = useHistory();
  if ((!sistema.actual || !sistema.actual.turno) && localStorage.actual) {
    sistema.actual = JSON.parse(localStorage.actual);
    if (!!sistema.actual.sector) {
      setSector(sistema.actual.sector);
    }
  }
  if (!sistema.actual || !sistema.actual.turno)
  {
    history.push('/posicion');
  }
  else
  {
    // levantamos las mesas del sector seleccionado (si es necesario)
    useEffect(() => {
      setSector(sistema.actual.sector);
      setHayTour(!!sistema.actual.sector.tour);
      setHayFoto(!!sistema.actual.sector.imagen);
      setHayMapa(!!sistema.actual.sector.mapa);
      setHayColor(!!sistema.actual.sector.color);
      // if (!sistema.actual.mesa)
      // {
        getUIData('getEscritorios', {
          espacioID: sistema.actual.espacio.id,
          sectorID: sistema.actual.sector.id,
          fecha: sistema.actual.fecha,
          turno: sistema.actual.turno
        },
          //      APIManager.getEscritorios(sistema.actual.espacio, sistema.actual.sector, sistema.actual.fecha, sistema.actual.turno,
        (escritorios) => {
/*           escritorios.forEach(item => {
            item.imagenDeSector = 'https://media.lamaquinita.co/sectores/DEV-imagenDeSector2_20220523170643.jpeg';
            item.imagen = 'https://media.lamaquinita.co/sectores/DEV-imagenDeSector2_20220523170643.jpeg';
          });
 */
          setMesas(escritorios);
          if (escritorios.length > 0) {
            const mesaPreSeleccionada = !!sistema.actual.mesa ? escritorios.find(item => item.id === sistema.actual.mesa.id) : escritorios[0];
            setMesa(mesaPreSeleccionada);
          }
        });
      // }
    }, []);
  }

  const disponiblesDe = (escritorio) => {
    return escritorio ? escritorio.disponibles + " disponibles" : '';
  }

  const puestoSeleccionado = (nuevaMesa, nuevoPuesto) => {
    setMesa(nuevaMesa);
    setPuesto(nuevoPuesto);
  }

  const continuar = () => {
    sistema.actual.mesa = mesa;
    sistema.actual.puesto = puesto;
    localStorage.actual = JSON.stringify(sistema.actual);
    history.push('/confirmar');
  }

  const toggleFullScreenImage = () => {
    setFullscreenImage(!fullscreenImage);
  }

  useEffect(() => {
    setSectorVisible(true);
  }, [mesa]);

  return (
    <div className="seleccion-de-escritorio-page">
      <TituloConBack titulo="Elegí el asiento en la mesa que prefieras" />
      <Selector className="selector-mesa" titulo="Mesa" selectedItem={mesa} items={mesas} icon="" onChange={setMesa} folded={mesasFolded} showInfo={(item) => disponiblesDe(item)} />
      {!!props.isPortrait
        ? (!!hayFoto || !!hayMapa) && verImagenes &&
        <>
          <div className={"layout--image-wrapper " + !!sectorVisible ? ' sector-visible' : ' mesa-visible'}>
            <div className="image__toggle-wrapper">
              <label htmlFor="imageToggle">Foto</label>
              <Switch className="image__toggle" sx={{ m: 1 }} disableRipple focusVisibleClassName=".Mui-focusVisible" id="imageToggle" checked={!sectorVisible} onChange={() => setSectorVisible(!sectorVisible)} />
              <label htmlFor="imageToggle">Mapa</label>
            </div>
            {!!sectorVisible
              ? hayFoto && verImagenes && <div className="sector__column">
                {hayTour
                  ? <div className="sector__tour--switch">
                    <button className={"sector__tour--button" + (!tourVisible ? " active" : "")} onClick={() => {setTourVisible(false)}}>FOTO</button>
                    <button className={"sector__tour--button" + (tourVisible ? " active" : "")} onClick={() => {setTourVisible(true)}}>TOUR</button>
                  </div>
                  : null
                }
                {tourVisible
                  ? <iframe className="sector__image" title="Tour Virtual" width='100%' height='100%' src='https://my.matterport.com/show/?m=twtkPbsRaCd&help=0&play=1&qs=1&brand=0&gt=0&hr=0&mls=2&cloudEdit=1&sr=-2.85,.3&ss=91&hl=2' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
                  // : <div className="sector__image info-de-escritorio__image" style={!!mesa ? { backgroundImage: 'url(' + mesa.imagenes.sector.url + ')' } : null} alt="Imagen Del Sector" />
                  : <div className="sector__image info-de-escritorio__image" style={!!mesa ? { backgroundImage: 'url(' + sector.imagen + ')' } : null} alt="Imagen Del Sector" />
                }
              </div>
              // : <div className={(fullscreenImage ? "fullscreen-image " : "") + "mesa__image info-de-escritorio__image"} style={!!mesa ? {display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundImage: 'url(' + mesa.imagenes.mesa.url + ')' } : null} alt="Imagen de la Mesa" onClick={toggleFullScreenImage}>
              : !!hayMapa && verImagenes && <div className={(fullscreenImage ? "fullscreen-image " : "") + "mesa__image info-de-escritorio__image"} style={!!mesa ? {display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundImage: 'url(' + sector.mapa + ')' } : null} alt="Mapa del sector" onClick={toggleFullScreenImage}>
                {!!mesa && <i className={"icon--search-" + (fullscreenImage ? "minus" : "plus") +" fa-6x"} style={{opacity: 0.1}} />}
              </div>
            }
          </div>
          <Mesa mesa={mesa} sector={sector} seleccionado={puesto} onChange={puestoSeleccionado} />
        </>
        : (!!hayFoto || !!hayMapa) && verImagenes &&
        <div className="wrapper--scrollable-content__layout">
          <div className="wrapper--scrollable-content__column sector__column">
            {hayTour
              ? <div className="sector__tour--switch">
                <button className={"sector__tour--button" + (!tourVisible ? " active" : "")} onClick={() => {setTourVisible(false)}}>FOTO</button>
                <button className={"sector__tour--button" + (tourVisible ? " active" : "")} onClick={() => {setTourVisible(true)}}>TOUR</button>
              </div>
              : null
            }
            {tourVisible
              ? <iframe className="sector__image" title="Tour Virtual" width='100%' height='100%' src='https://my.matterport.com/show/?m=twtkPbsRaCd&help=0&play=1&qs=1&brand=0&gt=0&hr=0&mls=2&cloudEdit=1&sr=-2.85,.3&ss=91&hl=2' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
              // : <div className="sector__image info-de-escritorio__image" style={!!mesa ? { backgroundImage: 'url(' + mesa.imagenes.sector.url + ')' } : null} alt="Imagen Del Sector" ></div>
              : <div className="sector__image info-de-escritorio__image" style={!!mesa ? { backgroundImage: 'url(' + sector.imagen + ')' } : null} alt="Imagen Del Sector" ></div>
            }
          </div>
          <div className="wrapper--scrollable-content__column">
            {/* <div className={(fullscreenImage ? "fullscreen-image " : "") + "mesa__image info-de-escritorio__image"} style={!!mesa ? { display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: ( fullscreenImage ? 'zoom-out' : 'zoom-in'), backgroundImage: 'url(' + mesa.imagenes.mesa.url + ')' } : null} alt="Imagen de la Mesa" onClick={toggleFullScreenImage} > */}
            <div className={(fullscreenImage ? "fullscreen-image " : "") + "mesa__image info-de-escritorio__image"} style={!!mesa ? { display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: ( fullscreenImage ? 'zoom-out' : 'zoom-in'), backgroundImage: 'url(' + sector.mapa + ')' } : null} alt="Imagen de la Mesa" onClick={toggleFullScreenImage} >
              {!!mesa && <i className={"icon--search-" + (fullscreenImage ? "minus" : "plus") +" fa-6x"} style={{opacity: 0.1}} />}
            </div>
            <Mesa mesa={mesa} color={sector.color} seleccionado={puesto} onChange={puestoSeleccionado} />
          </div>
        </div>
      }
      {((!hayFoto && !hayMapa) || !verImagenes) && <Mesa mesa={mesa} seleccionado={puesto} onChange={puestoSeleccionado} />}
      <FixedCTA disabled={!puesto} label="Continuar" onClick={continuar} />
    </div>
  );
}