import React from 'react';
import { useHistory } from 'react-router-dom';

export default ({usuario, ...props}) => {

  const history = useHistory();

  const pathClassName = (seccion) => {
    return props.seccion === seccion ? ' is-active' : '';
  }

  const setPage = (page) => {
    props.setSeccion(page);
    history.push(page);
  }

  return(
    <nav className="primary-navigation">
      <div className="wrapper--mqco-logo">
        <img className="mqco-logo" src="/img/mqco--logo.svg" alt="" onClick={() => {setPage("/home")}} />
      </div>
      <ul className="navigation-list">
        <li className="navigation-item">
          <button className="button--user-avatar" title="Avatar" onClick={props.showMenu}>
            <div className="button--user-avatar__content-wrapper">
              <i className="button--user-avatar__notification-icon icon--circle"></i>
              {usuario.avatar
                ? <img className="button--user-avatar__image" src={usuario.avatar} alt="" />
                : <div className="button--user-avatar__image"><span className="button--user-avatar__text">{(usuario.nombres ? usuario.nombres[0] : '') + (usuario.apellidos ? usuario.apellidos[0] : '')}</span></div>
              }
            </div>
          </button>
        </li>
        <li className={"navigation-item" + pathClassName('/home')}>
          <button className="navigation-item-link" onClick={() => {setPage("/home")}}>
            <i className="icon-- home"></i>
          </button>
        </li>
        <li className={"navigation-item" + pathClassName('/experiencias')}>
          <button className="navigation-item-link " onClick={() => {setPage("/experiencias")}}>
            <i className="icon-- experiencias"></i>
          </button>
        </li>
        <li className={"navigation-item" + pathClassName('/reservas')}>
          <button className="navigation-item-link " disabled={!(usuario.status === 'approved')} onClick={() => {(usuario.status === 'approved') && setPage("/reservas")}}>
            <i className="icon-- reservas"></i>
          </button>
        </li>
        <li className={"navigation-item" + pathClassName('/comunidad')}>
          <button className="navigation-item-link " onClick={() => {setPage("/comunidad")}}>
            <i className="icon-- comunidad"></i>
          </button>
        </li>
        <li className={"navigation-item" + pathClassName('/ayuda')}>
          <button className="navigation-item-link " onClick={() => {setPage("/ayuda")}}>
            <i className="icon-- ayuda"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
}