import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FixedCTA from "../../components/FixedCTA";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from "../../model/Sistema";
import 'moment/locale/es';
import Moment from "react-moment";
import { sendRequest } from '../../lib/serverAPI';
import moment from "moment";

export default ({usuario, ...props}) => {
  const history = useHistory();

  const [titulo, setTitulo] = useState(sistema.actual && sistema.actual.evento ? ("Resumen de " + sistema.actual.evento.tipo) : '');
  const [subtitulo, setSubtitulo] = useState(sistema.actual && sistema.actual.evento && sistema.actual.accion ? "Estás cancelando" : "Resumen de");
  const [cancelarHabilitado, setCancelarHabilitado] = useState(false);
  const [puedeCancelar, setPuedeCancelar] = useState(true);

  if ((!sistema.actual || !sistema.actual.evento) && localStorage.actual) {
    sistema.loadActual();

  }
  if (!sistema.actual || !sistema.actual.evento)
  {
    history.push('/home');
  }

  useEffect(() => {
    sistema.actual.costo = 0; // Por ahora no se devuelven creditos
  }, []);

  const descripcion = () => {
    switch (sistema.actual.accion) {
      case 'reservarEscritorio':
        return 'puesto ' + sistema.actual.puesto.id;
      case 'reservarSala':
        return sistema.actual.sala.nombre;
      default:
        return '';
    }
  }

  const confirmar = () => {
    sendRequest('post', 'reservas',sistema.actual.accion, sistema.actual, (response) => {
      if (response.success) {
        sistema.actual.reserva = response.data;
        // Calculamos la devolucion total
        sistema.actual.costo = 0;
        if (sistema.actual.reserva.transacciones) {
          sistema.actual.reserva.transacciones.forEach(devolucion => {
            sistema.actual.costo += parseFloat(devolucion.monto);
          });
        }

        sistema.usuario.creditos.balance += sistema.actual.costo;
        history.push('/exito');
      } else {
        // Hay errores
      }
    })

  }

  const detalle = () => {
    switch (sistema.actual.evento.subtipo) {
      case 'ESCRITORIO':
          return <>
          el día <span><Moment format="dddd D [de] MMMM" locale="es">{sistema.actual.evento.fecha}</Moment></span><br/>
          turno <span>{sistema.actual.evento.turno}</span>
        </>;
      case 'OFICINA':
        return <>
        el día <span><Moment format="dddd D [de] MMMM" locale="es">{sistema.actual.evento.fecha}</Moment></span><br/>
      </>;
      case 'SALA':
        return <>
          el día <span><Moment format="dddd D [de] MMMM" locale="es">{sistema.actual.evento.fecha}</Moment></span><br/>
          a las <span>{sistema.actual.evento.turno}</span>
        </>;

      default:
        return null;
    }
  }

  const detalleAviso = () => {
    switch (sistema.actual.evento.subtipo) {
      case 'ESCRITORIO':
        return <>
          Se realiza devolución de créditos según la anticipación de cancelación.<br />
          <ul>
            <li>Con más de 24hs, se devuelve el 100%</li>
            <li>Entre 12hs y 24hs, se devuelve el 50%</li>
            <li>Con menos de 12hs, no hay devolución</li>
          </ul>
        </>;
      case 'SALA':
        return <>
          Se realiza devolución del 100% de los créditos solo si se cancela con una anticipación mayor o igual a 4 hs.<br/>
        </>;

      default:
        return null;
    }
  }

  const subtipoString = (subtipo) => {
    switch (subtipo) {
      case 'ESCRITORIO':
        return 'PUESTO';
      case 'OFICINA':
        return 'puesto en tu OFICINA';
      default:
        return sistema.actual.evento.subtipo;
    }

  }

  useEffect(() => {
    const now = moment(new Date());
    sistema.actual.evento.inicio = moment(sistema.actual.evento.inicio);
    sistema.actual.evento.fin = moment(sistema.actual.evento.fin);

    switch (sistema.actual.evento.subtipo) {
      case 'ESCRITORIO':
        const medio = new Date((sistema.actual.evento.fin - sistema.actual.evento.inicio) / 2);
        switch (sistema.actual.evento.turno) {
          case 'Tarde':
            sistema.actual.evento.inicio.add(medio, 'milliseconds');
            break;
          case 'Mañana':
            sistema.actual.evento.fin.add(-medio, 'milliseconds');
            break;
          default:
        }
        break;
      default:
    }
    const fecha = sistema.actual.evento.fin;
    setPuedeCancelar(fecha > now);
  }, []);

  const showCancelar = () => {
    if (!puedeCancelar) {
      history.goBack();
    } else {
      sistema.actual.accion = 'cancelar'+sistema.actual.evento.tipo;
      setTitulo("Cancelación de " + sistema.actual.evento.tipo);
      setSubtitulo("Estás cancelando");
      setTimeout(() => {setCancelarHabilitado(true)}, 2000);
    }
  }

  const ctaLabel = () => {
    return puedeCancelar ? "Cancelar " + sistema.actual.evento.tipo : "Volver";
  }

  return ((!sistema || !sistema.actual || !sistema.actual.evento) ? null :
    <div className="cancelar-page">
      <TituloConBack titulo={titulo} />
      {!sistema.actual.accion ? null : <div className="cancelar__subtitulo">
        <span>Revisá que esté todo bien </span><span role="img" aria-label="ok">👍</span>
        <hr />
      </div>}
      <p className={"cancelar__detalle" + (sistema.actual.accion ? " alerta" : "")}>
        <span className="cancelar__detalle-subtitulo">{subtitulo}</span><br/>
        {sistema.actual.evento.titulo}<br/>
        {sistema.actual.evento.puesto && <> para el <span>puesto {sistema.actual.evento.puesto} </span><br/></>}
        {/* <span>{sistema.actual.evento.tipo} de {subtipoString(sistema.actual.evento.subtipo)} </span><br/> */}
        en <span>{sistema.actual.evento.ubicacion} </span><br/>
        {sistema.actual.espacio && <>de <span>{sistema.actual.espacio.nombre}</span><br/></>}
        {sistema.actual.sector ? <>sector <span>{sistema.actual.sector.nombre}</span><br/></> : null}
        {detalle()}
      </p>
      <p className="cancelar__aviso">{ detalleAviso() }</p>
      {sistema.actual.accion
        ? <FixedCTA disabled={!cancelarHabilitado} className={(sistema.actual.costo === 0) ? 'sin-leyenda' : ''}  titulo={(sistema.actual.costo !== 0) ? (sistema.actual.costo + " Créditos") : '' } leyendaPre="Te quedarán" leyendaBold={usuario.creditos.balance - sistema.actual.costo} leyendaPost="créditos" label="Confirmar" onClick={confirmar}/>
        : <FixedCTA className='sin-leyenda' label={ctaLabel()} onClick={showCancelar}/>
      }
    </div>
  );
}